import React from 'react';

import { sprintf } from '../../core/sprintf';
import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';

export class PackagingUvPhotographerTextsWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);

    this.codeElement = null;

    this.state = {
      isChosen: false,
      errors: [],
      errorsSum: 0,
      showErrors: false
    };
  }

  get name() {
    return 'uvPhotographerNameTexts';
  }

  get label() {
    return this.i18n.gettext('testo nome fotografo');
  }

  async collectErrors(item, value, index) {
    let errors = [];
    let maxLimit = item.maxTextLength;
    let minLimit = item.minTextLength;
    let isRequired = item.isRequired;

    if ((!value || value.length === 0) && isRequired) {
      this.delegate.filtersViewValidity = false;
      errors.push({
        message: this.i18n.gettext('Il testo non può essere vuoto'),
        rowCode: item.rowCode,
        code: 1
      });
    }
    // Max Limit
    if (value && value.length > maxLimit) {
      errors.push({
        message: sprintf(this.i18n.gettext('Inserire al massimo %s caratteri'), maxLimit),
        rowCode: item.rowCode,
        code: 2
      });
    }
    // Min Limit
    if (value && value.length < minLimit) {
      errors.push({
        message: sprintf(this.i18n.gettext('Inserire almeno %s caratteri'), minLimit),
        rowCode: item.rowCode,
        code: 3
      });
    }

    if (index === item.rowCode) {
      this.setState({
        [`errors${index}`]: errors
      });
    }
  }

  async handleChangeText(item, index, event) {
    this.setState({
      showErrors: true
    });
    let string = event.target.value;
    if (!/[^\u0000-\u00ff]/g.test(string)) { //eslint-disable-line
      await this.props.delegate.setConfigurationTexts(this.name, index, string);
      await this.collectErrors(item, string, item.rowCode);
    }
    this.propagateErrors();
  }

  handleKeyPress(event) {
    if (event.which === 13 && this.codeElement) {
      this.codeElement.focus();
    }
  }

  isValid() {
    let errorsSum = 0;
    for (let key in this.state) {
      if (key.indexOf('errors') !== -1 && this.state[key].length > 0) {
        errorsSum += this.state[key].length;
      }
    }
    return errorsSum === 0;
  }

  handleFocus() {
    return null;
  }

  async removeOLdErrors(items) {
    if (items && items.map) {
      await items.map((item) => {
        this.setState({
          [`errors${item.rowCode}`]: []
        });
      });
    }
  }

  async checkTextErrors(items) {
    await items.map((item) => {
      let errorValue = null;
      if (this.props.value[item.rowCode]) {
        errorValue = this.props.value[item.rowCode];
      }
      this.collectErrors(item, errorValue, item.rowCode);
    });
    this.propagateErrors();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    if (
      nextProps.items &&
      this.props.items &&
      this.props.items.length !== 0 &&
      nextProps.items.length === 0
    ) {
      /*
       * Some actions when widget is deactivated
       */
    }
    if (nextProps.items && nextProps.items.map && nextProps.items !== this.props.items) {
      this.removeOLdErrors(this.props.items);
      this.checkTextErrors(nextProps.items);
    }
    /*il widget aveva errori ma è stato disattivato, devo rimuoverli tutti*/
    if (!this.isValid() && (!this.props.items || this.props.items.length === 0)) {
      for (let key in this.state) {
        if (key.indexOf('errors') !== -1 && this.state[key].length > 0) {
          await this.setState({
            [key]: []
          });
        }
      }
      this.props.popWidgetError(this.name);
    }
  }

  renderTextItems(item) {
    let placeholder = item.name;
    if (item.minTextLength === 0) {
      placeholder = `${item.name} ${this.i18n.gettext('(opzionale)')}`;
    }
    let text = '';

    if (this.props.value && this.props.value[item.rowCode]) {
      text = this.props.value[item.rowCode];
    }

    return (
      <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
        <input
          type="text"
          className="form-control text__targa"
          placeholder={placeholder}
          value={text}
          onChange={this.handleChangeText.bind(this, item, item.rowCode)}
          onKeyPressCapture={this.handleKeyPress.bind(this)}
          onFocus={this.handleFocus.bind(this, item)}
        />
        <div>
          {this.state[`errors${item.rowCode}`].map((error) => {
            let errorHolder = item.rowCode === error.rowCode ? this.renderError(error) : null;
            return errorHolder;
          })}
        </div>
      </div>
    );
  }

  getItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        return (
          <div key={item.id} className="row">
            {this.renderTextItems(item)}
          </div>
        );
      });
    }
    return items;
  }

  renderError(error) {
    if (this.state.showErrors || this.props.delegate.getErrorsVisibility()) {
      return (
        <div key={error.code} className="alert alert-danger text-error" role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
          <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
          {error.message}
        </div>
      );
    }
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        {this.getItems()}
      </div>
    );
  }
}
