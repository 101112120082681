import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MaterialPreview } from '../../core/components/materialPreview';

export class MattedPrintsPackagingMaterialPreview extends MaterialPreview {
  static get propTypes() {
    return {
      product: PropTypes.object
    };
  }
  get imageUrl() {
    let imageUrl = this.props.product.previewUrl
      ? this.props.product.previewUrl
      : this.props.product.productItem.frontAssetUrl;
    return imageUrl ? imageUrl : null;
  }

  createSvg() {
    return { __html: this.props.product.previewSvg };
  }

  render() {
    let imageUrl = this.imageUrl;

    let loadingClass = classNames('img-container', { 'loading-widget': !imageUrl });

    let height = null;
    let width = null;
    if (this.props.height) {
      height = this.props.height + 'px';
    }
    if (this.props.width) {
      width = this.props.width + 'px';
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={loadingClass} style={{ height, width }}>
            {imageUrl ? <img className="img-responsive" src={imageUrl} /> : imageUrl}
          </div>
          <div
            className="svg-container svg-matted-prints-packaging-container"
            dangerouslySetInnerHTML={this.createSvg()}
            style={{ height, width }}></div>
        </div>
      </div>
    );
  }
}
