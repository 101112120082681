import { BaseSelectionWidgetFourColumns } from '../../core/components/baseSelectionWidgetFourColumns';

export class PackagingUvGraphicThemeWidget extends BaseSelectionWidgetFourColumns {
  get name() {
    return 'packagingUvGraphicThemeType';
  }

  get label() {
    return this.i18n.gettext('grafiche per evento');
  }

  get calculatePrice() {
    return false;
  }

  //Need if you shouldn't call price API
  // propagateClick() {
  //   if (this.clickedItem) {
  //     this.setState({isChosen: true});
  //     this.props.delegate.setConfigurationItem(this.name, this.clickedItem.id, null, true);
  //   }
  // }
}
