import { BaseConfiguratorProductStorage } from '../core/storage/index';
import { MattedPrintsPackagingConfiguration } from './models';

export class MattedPrintsPackagingStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'MattedPrintsPackaging';
  }
}

export async function getMattedPrintsPackagingConfiguration(configurationId, params) {
  let configuration = null;
  if (configurationId) {
    configuration = new MattedPrintsPackagingConfiguration({ id: configurationId });
    if (params) {
      await configuration.fetch({ data: params });
    } else {
      await configuration.fetch();
    }
  }
  return configuration;
}
