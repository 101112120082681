import { runtime } from '@outlinejs/contexts';
import { conf } from 'outlinejs';
import { BaseConfiguratorController } from '../core/controllers';
import { GuiErrors, createProfessionalPreOrder } from '../core/utils/index';
import Logger from '../core/logger';
import { mattedPrintsFunnelSteps } from '../core/utils/breadcrumb';

import { MattedPrintsPackagingContentView } from './views';
import {
  MattedPrintsPackagingProductCollection,
  MattedPrintsPackagingPriceCollection,
  MattedPrintsPackagingTypeCollection,
  MattedPrintsPackagingColorMaterialTypesCollection,
  MattedPrintsPackagingUvGraphicsCollection,
  MattedPrintsPackagingUvGraphicThemeCollection,
  MattedPrintsPackagingUvGraphicElementCollection,
  MattedPrintsPackagingUvGraphicTextsCollection,
  MattedPrintsPackagingUvPhotographerNameCollection,
  MattedPrintsPackagingUvPhotographerTextsCollection,
  MattedPrintsPackagingRibbonColorTypesCollection
} from './managers';
import { MattedPrintsPackagingConfiguration } from './models';
import { getMattedPrintsPackagingConfiguration } from './utils';
import {
  getMattedPrintsConfiguration,
  getConfigurationOptionalPriceParams,
  getConfigurationRequiredPriceParams,
  getMattedPrintsAccessoryConfiguration
} from '../matted-prints/utils';
import { PackagingConfigurationError, InvalidPackagingConfigurationError } from './errors';
import { navigateTo } from '../core/utils/index';
import { MattedPrintsAccessoryConfigurationCollection } from '../matted-prints/managers';
import { getPreOrder, mergeQueryParams } from '../core/utils';
import { MattedPrintsPriceCollection } from '../matted-prints/managers';
import EventTracker, { productCategoryInterface, productInterface } from '../core/eventTracker';

/**
 * Main controller for album packaging
 * */
export class MattedPrintsPackagingController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get funnelStep() {
    return 2;
  }

  get funnelSteps() {
    return mattedPrintsFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.i18n.gettext('Scatola');
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product,
      configuration: this.configuration,
      filters: this.filters,
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      initViewRendering: this.initViewRendering,
      loadingCollection: this.loadingCollection,
      loadingPage: this.loadingPage,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      user: this.user,
      customerUser: this.customerUser,
      updatePrice: this.updatePrice
    });
  }

  get view() {
    return MattedPrintsPackagingContentView;
  }

  async getMattedPrintsPreOrder(configurationId) {
    return await getPreOrder(configurationId, conf.settings.MATTED_PRINTS_PRODUCT_TYPE);
  }

  async getMattedPrintsPackagingPreOrderId(packagingConfigurationId) {
    let preorder = await getPreOrder(
      packagingConfigurationId,
      conf.settings.MATTED_PRINTS_PACKAGING_PRODUCT_TYPE
    );
    if (preorder) {
      return preorder.id;
    } else {
      return null;
    }
  }

  async getMattedPrintsAccessoryPreOrderId(accessoryConfigurationId) {
    if (accessoryConfigurationId) {
      let preorder = await getPreOrder(
        accessoryConfigurationId,
        conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE
      );
      if (preorder) {
        return preorder.id;
      } else {
        return null;
      }
    }
    return null;
  }

  async initPreOrders(configurationId, packagingConfigurationId, accessoryConfigurationId) {
    let professionalProjectId = null;
    let mattedPrintsPreOrder;
    let mattedPrintsPreOrderId = null;
    let mattedPrintsPackagingPreOrderId;
    let mattedPrintsAccessoryPreOrderId;

    [mattedPrintsPreOrder, mattedPrintsPackagingPreOrderId, mattedPrintsAccessoryPreOrderId] =
      await Promise.all([
        this.getMattedPrintsPreOrder(configurationId),
        this.getMattedPrintsPackagingPreOrderId(packagingConfigurationId),
        this.getMattedPrintsAccessoryPreOrderId(accessoryConfigurationId)
      ]);

    if (mattedPrintsPreOrder) {
      mattedPrintsPreOrderId = mattedPrintsPreOrder.id;
      professionalProjectId = mattedPrintsPreOrder.projectId;
    }

    return [
      professionalProjectId,
      mattedPrintsPreOrderId,
      mattedPrintsPackagingPreOrderId,
      mattedPrintsAccessoryPreOrderId
    ];
  }

  async initContentProps() {
    super.initContentProps();

    this.product = {
      productItem: {},
      previewUrl: null,
      previewSvg: null,
      previewSvgUrl: null,
      productConfiguration: new MattedPrintsPackagingConfiguration(),
      svgColor: null,
      chosenSvgColor: null,
      price: {},
      showWidgetPrice: this.customerUserIsAuthorized,
      productPrice: {}
    };
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      await this.loadInitialConfiguration();
      await this.loadProductConfigurationPrice();
      this.reloadView();

      EventTracker.log(
        this.customerUser,
        'configurator_packaging_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async loadInitialConfiguration() {
    try {
      let packagingConfigurationId = +this.request.query.packagingConfigurationId;
      let packagingConfiguration = await getMattedPrintsPackagingConfiguration(
        packagingConfigurationId
      );

      if (!packagingConfiguration) {
        throw new InvalidPackagingConfigurationError('Impossible to load packaging configuration');
      }
      this.product.productConfiguration = packagingConfiguration;
      this.configuration = packagingConfiguration.toJSON(); // la configurazione è un JSON (NON un backbone model)
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        this.response.navigate('matted-prints:new', {});
      } else {
        if (error instanceof InvalidPackagingConfigurationError) {
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile caricare la configurazione della scatola, si prega di ripetere la procedura.'
            ),
            'Error detail: loadMattedPrintsPackagingConfiguration - code: ' + timestamp
          );
          navigateTo(this.request, this.response, 'matted-prints:main', {}, true, {
            configurationId: this.product.productConfiguration.mattedPrintsConfigurationId
          });
        } else {
          new PackagingConfigurationError(`MattedPrintsPackagingController:init`, { //eslint-disable-line
            packagingConfiguration: this.configuration,
            error: error
          });
        }
      }
    } finally {
      this.stopInitialRendering();
    }
  }

  async loadProductConfigurationPrice() {
    let productConfiguration;
    let accessoryConfiguration;
    try {
      productConfiguration = await getMattedPrintsConfiguration(this.request.query.configurationId);
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        navigateTo(this.request, this.response, 'matted-prints:new', {}, false, null);
      } else {
        new PackagingConfigurationError('MattedPrintsPackagingController.loadProductConfigurationPrice - failed to fetch MattedPrints productConfiguration', { //eslint-disable-line
            packagingConfiguration: this.configuration,
            productConfigurationId: this.request.query.configurationId,
            error: error
          }
        );
      }
    } finally {
      this.stopInitialRendering();
    }

    if (productConfiguration) {
      accessoryConfiguration = await getMattedPrintsAccessoryConfiguration(
        this.request.query.configurationId,
        productConfiguration.productType
      );
      let priceOptionalParams = await getConfigurationOptionalPriceParams(
        null,
        productConfiguration
      );
      let priceRequiredParams = await getConfigurationRequiredPriceParams(
        productConfiguration,
        accessoryConfiguration
      );

      priceRequiredParams = Object.assign(priceRequiredParams, {
        shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
      });

      let priceParams = Object.assign(priceRequiredParams, priceOptionalParams);

      try {
        let price = await this.getPrice(new MattedPrintsPriceCollection(), priceParams);
        this.productPrice = price;
        this.setProductConfigurationPrice(price);
        this.setProductConfigurationName(productConfiguration.productName);
      } catch (error) {
        Logger.error('MattedPrintsPackaging - Loading product price error', {
          error: error,
          priceParams: priceParams,
          productConfiguration: productConfiguration
        });
      }
    }
  }

  getPreviewTexts() {
    return this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length === 0
      ? this.configuration.packagingUvGraphicTexts
      : this.configuration.packagingUvGraphicTexts;
  }

  async reloadView(getPrice) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();

      await this.loadCollection(new MattedPrintsPackagingTypeCollection());
      /*
       * Inizializzare la fase di rendering prima dei widget UV, altrimenti non vengono caricati gli SVG
       * */

      await Promise.all([
        this.loadCollection(new MattedPrintsPackagingColorMaterialTypesCollection()),
        this.loadCollection(new MattedPrintsPackagingUvGraphicsCollection())
      ]);

      await Promise.all([
        this.loadCollection(new MattedPrintsPackagingRibbonColorTypesCollection()),
        this.loadCollection(new MattedPrintsPackagingUvGraphicThemeCollection())
      ]);

      await this.loadProduct();

      await this.loadUvGraphicElementsCollection(
        new MattedPrintsPackagingUvGraphicElementCollection(),
        {
          uvGraphicElementSvgName: 'packagingUvGraphicElementSvgName',
          uvGraphicElementType: 'packagingUvGraphicElementType'
        }
      );

      await this.loadCollection(new MattedPrintsPackagingUvPhotographerNameCollection());

      await Promise.all([
        this.loadCollection(new MattedPrintsPackagingUvGraphicTextsCollection()),
        this.loadCollection(new MattedPrintsPackagingUvPhotographerTextsCollection())
      ]);
    } catch (err) {
      Logger.error('MattedPrintsPackagingController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice = true) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('AlbumPackagingController.reloadView-emptyfilters', {
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('MattedPrintsPackagingController.reloadView-loadProduct', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  async loadProduct() {
    let productParams = {
      formatType: this.configuration.formatType,
      packagingType: this.configuration.packagingType
    };

    if (
      this.filters.packagingColorMaterialTypes &&
      this.filters.packagingColorMaterialTypes.length > 0
    ) {
      productParams.packagingColorMaterialType = this.configuration.packagingColorMaterialType;
    }

    if (this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0) {
      productParams.packagingUvGraphicType = this.configuration.packagingUvGraphicType;
    }

    if (
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
    ) {
      productParams.packagingUvGraphicElementType =
        this.configuration.packagingUvGraphicElementType;
    }

    if (
      this.filters.packagingUvPhotographerNameTypes &&
      this.filters.packagingUvPhotographerNameTypes.length > 0
    ) {
      productParams.uvPhotographerName = this.configuration.uvPhotographerName;
    }

    if (this.filters.ribbonColorTypes && this.filters.ribbonColorTypes.length > 0) {
      productParams.ribbonColorType = this.configuration.ribbonColorType;
    }

    let product = await this.getProduct(
      new MattedPrintsPackagingProductCollection(),
      productParams
    );
    if (product) {
      this.setMattedPrintsPackagingProduct(product);
    }
  }

  async loadPrice() {
    let priceParams = {
      formatType: this.configuration.formatType,
      packagingType: this.configuration.packagingType,
      language: this.getLanguage()
    };

    if (this.customerUserIsAuthorized) {
      priceParams.shopCode = this.customerUser.shopCode;
    }

    if (
      this.filters.packagingColorMaterialTypes &&
      this.filters.packagingColorMaterialTypes.length > 0
    ) {
      priceParams.packagingColorMaterialType = this.configuration.packagingColorMaterialType;
    }

    if (this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0) {
      priceParams.packagingUvGraphicType = this.configuration.packagingUvGraphicType;
    }

    if (
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
    ) {
      priceParams.packagingUvGraphicElementType = this.configuration.packagingUvGraphicElementType;
    }

    if (
      this.filters.packagingUvPhotographerNameTypes &&
      this.filters.packagingUvPhotographerNameTypes.length > 0
    ) {
      priceParams.uvPhotographerName = this.configuration.uvPhotographerName;
    }

    if (this.filters.ribbonColorTypes && this.filters.ribbonColorTypes.length > 0) {
      priceParams.ribbonColorType = this.configuration.ribbonColorType;
    }

    let price = await this.getPrice(new MattedPrintsPackagingPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  async setMattedPrintsPackagingProduct(productItem) {
    super.setConfigurationProduct(productItem);
    this.render(this.context);
  }

  getPreparedConfiguration(configuration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!configuration) {
      configuration = this.configuration;
    }
    configuration.shopCode = shopcode;
    configuration.packagingColorMaterialType =
      this.filters.packagingColorMaterialTypes &&
      this.filters.packagingColorMaterialTypes.length > 0
        ? configuration.packagingColorMaterialType
        : null;
    configuration.packagingUvGraphicType =
      this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0
        ? configuration.packagingUvGraphicType
        : null;
    configuration.packagingUvGraphicElementType =
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
        ? configuration.packagingUvGraphicElementType
        : null;
    configuration.packagingUvGraphicThemeType =
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
        ? configuration.packagingUvGraphicThemeType
        : null;
    configuration.uvPhotographerName =
      this.filters.packagingUvPhotographerNameTypes &&
      this.filters.packagingUvPhotographerNameTypes.length > 0
        ? configuration.uvPhotographerName
        : null;
    configuration.packagingUvGraphicTexts =
      this.filters.packagingUvGraphicTextsCollection &&
      this.filters.packagingUvGraphicTextsCollection.length > 0
        ? configuration.packagingUvGraphicTexts
        : {};
    configuration.uvPhotographerNameTexts =
      this.filters.packagingUvPhotographerTextsCollection &&
      this.filters.packagingUvPhotographerTextsCollection.length > 0
        ? configuration.uvPhotographerNameTexts
        : {};
    configuration.ribbonColorType =
      this.filters.ribbonColorTypes && this.filters.ribbonColorTypes.length > 0
        ? configuration.ribbonColorType
        : null;

    return configuration;
  }

  async saveProductConfiguration() {
    try {
      let currentConfiguration = this.getPreparedConfiguration();
      let productConfiguration = this.product.productConfiguration;
      let configuration = await productConfiguration.save(currentConfiguration);
      Logger.info('MattedPrintsPackaging Configuration id:', this.product.productConfiguration.id);
      return configuration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('MattedPrintsPackagingController.saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      navigateTo(
        this.request,
        this.response,
        'matted-prints-packaging:main',
        {},
        true,
        this.request.query
      );
      return false;
    }
  }

  async getAccessoryConfiguration(mattedPrintsConfiguration) {
    let accessoryConfigurationsCollection;
    let accessoryExistingConfiguration = null;
    let accessoryConfiguration = new MattedPrintsAccessoryConfigurationCollection();
    try {
      accessoryConfigurationsCollection = await accessoryConfiguration.fetch({
        data: {
          mattedPrintsConfigurationId: mattedPrintsConfiguration.id,
          mattedPrintsProductType: mattedPrintsConfiguration.productType
        },
        processData: true
      });
    } catch (err) {
      Logger.error(
        'MattedPrintsPackagingController.getAccessoryConfiguration - unable to fetch accessory existing accessory configurations',
        {
          error: err
        }
      );
    }
    if (accessoryConfigurationsCollection.length > 0) {
      await accessoryConfigurationsCollection.map((accessory) => {
        if (accessory.productType === conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE) {
          accessoryExistingConfiguration = accessory;
        }
      });
    }
    return accessoryExistingConfiguration;
  }

  async savePreOrders() {
    /** get stored configurations **/
    let mattedPrintsConfiguration = await getMattedPrintsConfiguration(
      this.request.query.configurationId
    );
    let accessoryConfiguration = await this.getAccessoryConfiguration(mattedPrintsConfiguration);
    let packagingConfiguration = this.product.productConfiguration;
    let timestamp = new Date().getTime();
    let updateMattedPrintsConfiguration = false;
    let updateAccessoryConfiguration = false;
    let promises = [];

    /** check configurations **/
    if (!mattedPrintsConfiguration || !packagingConfiguration) {
      GuiErrors.modalError(
        this.i18n.gettext(
          'Qualcosa è andato storto. Ti preghiamo di riprovare riconfigurando un nuovo prodotto.'
        ),
        'Error detail: preorder creation, configurations not found - code: ' + timestamp
      );
      this.response.navigate('matted-prints:main');
      return [false, new Error('Configurations not found')];
    }

    /** check shop codes **/
    if (!mattedPrintsConfiguration.shopCode) {
      updateMattedPrintsConfiguration = true;
      promises.push(mattedPrintsConfiguration.save({ shopCode: this.customerUser.shopCode }));
    }

    if (accessoryConfiguration && !accessoryConfiguration.shopCode) {
      updateAccessoryConfiguration = true;
      promises.push(accessoryConfiguration.save({ shopCode: this.customerUser.shopCode }));
    }

    // update shopcodes on configurations
    if (updateMattedPrintsConfiguration || updateAccessoryConfiguration) {
      if (this.customerUser.shopCode !== packagingConfiguration.shopCode) {
        Logger.error('MattedPrintsPackagingController.savePreOrders - different shopcodes', {
          errorCode: timestamp,
          user: this.user,
          customerUser: this.customerUser,
          mattedPrintsConfiguration: mattedPrintsConfiguration,
          accessoryConfiguration: accessoryConfiguration,
          packagingConfiguration: packagingConfiguration
        });
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: updateMattedPrintsConfigurations - code: ' + timestamp
        );
        return [false, new Error('ShopCode not valid')];
      }

      await Promise.all(promises);
    }

    /** check pre-orders **/
    if (!(this.request.query.configurationId && this.request.query.packagingConfigurationId)) {
      return [false, new Error('Configurations not found')];
    }
    let accessoryConfigurationId = accessoryConfiguration ? accessoryConfiguration.id : null;
    [this.projectId, this.preOrderId, this.packagingPreOrderId, this.accessoryPreorderId] =
      await this.initPreOrders(
        this.request.query.configurationId,
        this.request.query.packagingConfigurationId,
        accessoryConfigurationId
      );
    if (this.projectId && this.preOrderId && this.packagingPreOrderId) {
      return [true, undefined];
    }

    /** create pre-orders **/
    try {
      let mattedPrintsPreorder = await createProfessionalPreOrder(
        this.customerUser,
        mattedPrintsConfiguration,
        null,
        1,
        mattedPrintsConfiguration.configurationGuid
      );
      this.preOrderId = mattedPrintsPreorder.id;
      this.projectId = mattedPrintsPreorder.projectId;
      let mattedPrintsPackagingPreorder = await createProfessionalPreOrder(
        this.customerUser,
        packagingConfiguration,
        this.projectId
      );
      this.packagingPreOrderId = mattedPrintsPackagingPreorder.id;
      if (
        accessoryConfiguration &&
        accessoryConfiguration.standType !== conf.settings.MATTED_PRINTS_STAND_NO
      ) {
        let accessoryPreorder = await createProfessionalPreOrder(
          this.customerUser,
          accessoryConfiguration,
          this.projectId,
          accessoryConfiguration.quantity
        );
        this.accessoryPreorderId = accessoryPreorder.id;
      }

      EventTracker.log(this.customerUser, 'bucket_add_success', {
        product_category: productCategoryInterface(this.product.productConfiguration.productType),
        project_id: this.projectId
      });

      return [true, undefined];
    } catch (err) {
      Logger.error('MattedPrints.savePreOrders - createPreOrder', {
        error: err,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser,
        mattedPrintsConfiguration: mattedPrintsConfiguration,
        accessoryConfiguration: accessoryConfiguration,
        packagingConfiguration: packagingConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: createPreOrder - code: ' + timestamp,
        this.i18n.gettext('Chiudi'),
        null,
        null
      );
      return [false, err];
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    if (await this.checkWidgetErrors()) {
      let packagingConfiguration = await this.saveProductConfiguration();

      if (!this.isUserLoggedIn()) {
        this.redirectToLoginPage();
        return null;
      }

      if (this.customerUser && !this.customerUser.shopCode) {
        let timestamp = new Date().getTime();
        this.stopLoadingPage();
        GuiErrors.modalError(
          this.i18n.gettext('User not authorized to add product to cart'),
          'Error detail: shopCode null - code: ' + timestamp,
          this.i18n.gettext('Chiudi'),
          null,
          null
        );
        return false;
      }

      if (packagingConfiguration) {
        let [preOrderResult, error] = await this.savePreOrders();
        if (preOrderResult) {
          navigateTo(
            this.request,
            this.response,
            'services:MattedPrints',
            {},
            false,
            mergeQueryParams(this.request.query, {
              preOrderId: this.preOrderId,
              packagingPreOrderId: this.packagingPreOrderId,
              professionalProjectId: this.projectId
            })
          );
        } else {
          EventTracker.log(this.customerUser, 'bucket_add_error', {
            product_category: productCategoryInterface(
              this.product.productConfiguration.productType
            ),
            error_code: String(error)
          });
        }
      }
    }
    this.stopLoadingPage();
  }

  /*
   * Graphic UV left preview handling functions section
   */

  setPreviewImage(url) {
    this.product.previewUrl = url;
    this.render(this.context);
  }

  fixSvg(svg, texts, color) {
    this.fixSvgTexts(svg, texts);
    if (color) {
      this.fixSvgColor(svg, color);
    }

    /* save the svg xml element as ready to use text */
    let s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  fixSvgColor(svg, color) {
    /*fix the svg color for all svg elements*/
    let allElements = svg.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].setAttribute('fill', color);
    }
  }

  fixSvgTexts(svg, texts) {
    if (
      this.isSvgTextTypedByUser() ||
      (this.getPreviewTexts() && Object.keys(this.getPreviewTexts()).length > 0)
    ) {
      let svgTextElements = svg.querySelectorAll('text');

      for (let i = 0; i <= svgTextElements.length; i++) {
        let svgTextElement = svgTextElements[i];

        if (svgTextElement) {
          let svgTextElementId = svgTextElement.getAttribute('id');

          if (svgTextElementId) {
            texts = this.getPreviewTexts()[svgTextElementId];
            let text = '';
            if (texts) {
              text = texts;
            }
            svgTextElement.childNodes[0].nodeValue = text;
          } else {
            console.error('SVG have text component without id');
          }
        }
      }
    }
  }

  async setPreviewSvg(svgUrl, texts, color = this.product.svgColor) {
    svgUrl = svgUrl || this.product.previewSvgUrl;
    if (svgUrl) {
      var request = new XMLHttpRequest();
      await request.open('GET', svgUrl, true);

      request.onload = function () {
        var data = request.responseXML;
        let svg = data.getElementsByTagName('svg')[0];
        this.product.previewSvg = this.fixSvg(svg, texts, color);
        this.render(this.context);
      }.bind(this);

      request.onerror = function (err) {
        Logger.error('setPreviewSvg - Error on SVG file retrieve', {
          url: svgUrl,
          error: err
        });
      };
      request.send();
    }
  }

  clearPreviewSvg() {
    this.product.previewSvgUrl = null;
    this.product.previewSvg = null;
  }

  chosePreviewSvg(svgUrl) {
    this.product.previewSvgUrl = svgUrl;
  }

  setPreviewSvgColor(color) {
    if (color) {
      this.product.svgColor = color;
    } else {
      this.product.svgColor = this.product.chosenSvgColor;
    }
  }

  chosePreviewSvgColor(color) {
    color = color || this.product.svgColor;
    this.product.chosenSvgColor = this.product.svgColor = color;
  }

  resetPreviewSvgColor() {
    this.product.svgColor = null;
    this.product.chosenSvgColor = null;
  }

  chosePreviewTexLimit(rowLimitMin, rowLimitMax) {
    this.product.previewTexLimitMin = rowLimitMin;
    this.product.previewTexLimitMax = rowLimitMax;
  }

  chosePreviewSvgText() {
    this.product.SvgTextTyped = true;
  }

  isSvgTextTypedByUser() {
    return this.product.SvgTextTyped;
  }

  /** END OF left preview handling functions section **/
}
