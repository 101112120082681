import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class PackagingColorMaterialTypesWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'packagingColorMaterialType';
  }

  get label() {
    return this.i18n.gettext('colore confezione');
  }

  get calculatePrice() {
    return false;
  }
}
