import CustomError from '../core/utils/errors';

export class InvalidPackagingConfigurationError extends CustomError {
  constructor(m, extraInfo) {
    super(m, extraInfo);
  }
}

export class PackagingConfigurationError extends CustomError {
  constructor(m, extraInfo) {
    super(m, extraInfo);
  }
}
