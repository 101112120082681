import { BaseSelectionWidgetWithImages } from '../../core/components/baseSelectionWidgetWithImages';

export class PackagingUvPhotographerNameWidget extends BaseSelectionWidgetWithImages {
  get name() {
    return 'uvPhotographerName';
  }

  get label() {
    return this.i18n.gettext('nome fotografo');
  }

  get calculatePrice() {
    return false;
  }
}
