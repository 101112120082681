import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { MattedPrintsPackagingMaterialPreview } from './components/mattedPrintsPackagingMaterialPreview';

import { PackagingTypesWidget } from './components/packagingTypesWidget';
import { PackagingColorMaterialTypesWidget } from './components/packagingColorMaterialTypesWidget';
import { PackagingUvGraphicThemeWidget } from './components/packagingUvGraphicThemeWidget';
import { PackagingUvGraphicElementWidget } from './components/packagingUvGraphicElementWidget';
import { PackagingUvGraphicTextWidget } from './components/packagingUvGraphicTextWidget';
import { RibbonColorsWidget } from './components/ribbonColorsWidget';
import { PackagingUvGraphicCollectionWidget } from './components/packagingUvGraphicCollectionWidget';
import { PackagingUvPhotographerNameWidget } from './components/packagingUvPhotographerNameWidget';
import { PackagingUvPhotographerTextsWidget } from './components/packagingUvPhotographerTextsWidget';
import { FiltersTitle } from '../core/components/filtersTitle';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.eventBookPackagingPrice ||
        this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <MattedPrintsPackagingMaterialPreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle key="FiltersTitle" title={this.i18n.gettext('Personalizza la confezione')} />,

      <PackagingTypesWidget
        key="PackagingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingTypes}
        selected={this.props.configuration.packagingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingColorMaterialTypesWidget
        key="PackagingColorMaterialTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingColorMaterialTypes}
        selected={this.props.configuration.packagingColorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <RibbonColorsWidget
        key="RibbonColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.ribbonColorTypes}
        selected={this.props.configuration.ribbonColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicCollectionWidget
        key="PackagingUvGraphicCollectionWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTypes}
        selected={this.props.configuration.packagingUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicThemeWidget
        key="PackagingUvGraphicThemeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicThemeTypes}
        selected={this.props.configuration.packagingUvGraphicThemeType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicElementWidget
        key="PackagingUvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementTypes}
        selected={this.props.configuration.packagingUvGraphicElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicTextWidget
        key="PackagingUvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTextsCollection}
        value={this.props.configuration.packagingUvGraphicTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvPhotographerNameWidget
        key="PackagingUvPhotographerNameWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvPhotographerNameTypes}
        selected={this.props.configuration.uvPhotographerName}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvPhotographerTextsWidget
        key="PackagingUvPhotographerTextsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvPhotographerTextsCollection}
        value={this.props.configuration.uvPhotographerNameTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class MattedPrintsPackagingContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
  }
}
