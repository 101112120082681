import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  MattedPrintsPackagingProduct,
  MattedPrintsPackagingPrice,
  MattedPrintsPackagingType,
  MattedPrintsPackagingColorMaterialType,
  MattedPrintsRibbonColorType,
  MattedPrintsPackagingUvGraphicType,
  MattedPrintsPackagingUvGraphicThemeType,
  MattedPrintsPackagingUvGraphicElementType,
  MattedPrintsPackagingUvGraphicText,
  MattedPrintsPackagingUvPhotographerNameType,
  MattedPrintsPackagingUvPhotographerNameText
} from './models';

export class MattedPrintsPackagingProductCollection extends LoadableCollection {
  get name() {
    return 'mattedPrintsPackagingProducts';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_PRODUCTS_URL;
  }

  get model() {
    return MattedPrintsPackagingProduct;
  }
}

export class MattedPrintsPackagingPriceCollection extends LoadableCollection {
  get name() {
    return 'mattedPrintsPackagingPrice';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_PRICES_URL;
  }

  get model() {
    return MattedPrintsPackagingPrice;
  }
}

export class MattedPrintsPackagingTypeCollection extends LoadableCollection {
  get name() {
    return 'packagingTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_TYPES_URL;
  }

  get model() {
    return MattedPrintsPackagingType;
  }

  get loadParams() {
    return ['formatType'];
  }
}

export class MattedPrintsPackagingColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingColorMaterialTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_COLOR_MATERIALS_URL;
  }

  get model() {
    return MattedPrintsPackagingColorMaterialType;
  }

  get loadParams() {
    return ['formatType', 'packagingType'];
  }
}

export class MattedPrintsPackagingUvGraphicsCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL;
  }

  get model() {
    return MattedPrintsPackagingUvGraphicType;
  }

  get loadParams() {
    return ['formatType', 'packagingType'];
  }
}

export class MattedPrintsPackagingUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_GRAPHIC_THEMES_URL;
  }

  get model() {
    return MattedPrintsPackagingUvGraphicThemeType;
  }

  get loadParams() {
    return ['formatType', 'packagingType', 'packagingUvGraphicType'];
  }
}

export class MattedPrintsPackagingUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_GRAPHIC_ELEMENTS_URL;
  }

  get model() {
    return MattedPrintsPackagingUvGraphicElementType;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'ribbonColorType',
      'packagingColorMaterialType'
    ];
  }
}

export class MattedPrintsPackagingUvGraphicTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvGraphicTextsCollection';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_GRAPHIC_TEXTS_URL;
  }

  get model() {
    return MattedPrintsPackagingUvGraphicText;
  }

  get loadParams() {
    return [
      'formatType',
      'packagingType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'packagingUvGraphicElementType'
    ];
  }
}

export class MattedPrintsPackagingUvPhotographerNameCollection extends LoadableCollection {
  get name() {
    return 'packagingUvPhotographerNameTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_PHOTOGRAPHER_NAMES_URL;
  }

  get model() {
    return MattedPrintsPackagingUvPhotographerNameType;
  }

  get loadParams() {
    return ['formatType', 'packagingType'];
  }
}

export class MattedPrintsPackagingUvPhotographerTextsCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvPhotographerTextsCollection';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_UV_PHOTOGRAPHER_NAME_TEXTS_URL;
  }

  get model() {
    return MattedPrintsPackagingUvPhotographerNameText;
  }

  get loadParams() {
    return ['formatType', 'packagingType', 'uvPhotographerName'];
  }
}

export class MattedPrintsPackagingRibbonColorTypesCollection extends LoadableCollection {
  get name() {
    return 'ribbonColorTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PACKAGING_RIBBON_COLORS_URL;
  }

  get model() {
    return MattedPrintsRibbonColorType;
  }

  get loadParams() {
    return ['packagingType', 'packagingColorMaterialType'];
  }
}
