import { GraphicCollapsableWidgetWithText } from '../../core/components/graphicCollapsableWidgetWithText';

export class RibbonColorsWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'ribbonColorType';
  }

  get label() {
    return this.i18n.gettext('colore nastro');
  }

  get calculatePrice() {
    return false;
  }
}
